
export class TextTag {
  textElement = null

  constructor (textElement) {
    this.textElement = textElement
  }

  // eslint-disable-next-line complexity
  addFormatText (type) {
    const message = this.textElement
    const from = message.selectionStart
    const to = message.selectionEnd
    const textSelected = message.value.substring(from, to)

    switch (type) {
      case 'strong':
      return this.addHtmlTag(message, from, to, textSelected, '*', '*')
    case 'italic':
      return this.addHtmlTag(message, from, to, textSelected, '_', '_')
    case 'strike':
      return this.addHtmlTag(message, from, to, textSelected, '~', '~')
    case 'monospace':
      return this.addHtmlTag(message, from, to, textSelected, '```', '```')
    case 'list':
      return this.addListPrefix(message, from, to, textSelected, '-')
    case 'numberedList':
      return this.addNumberedListPrefix(message, from, to, textSelected)
    case 'quote':
      return this.AddQuotePrefix(message, from, to, textSelected, '>')
    case 'aligned':
      return this.addHtmlTag(message, from, to, textSelected, '`', '`')
    }
  }

  AddQuotePrefix (message, from, to, textSelected, firstTag) {
    if (textSelected.length > 0) {
      message.setRangeText(`${firstTag} ${textSelected}`, from, to, 'select')
      return message.value
    }
    return null
  }

  addNumberedListPrefix (message, from, to, textSelected) {
    if (textSelected.length > 0) {
      const tmp = textSelected.split('\n')
      const res = []
      let count = 1
      for (const frag of tmp) {
        res.push(`${count}. ${frag}`)
        count++
      }

      const replaced = res.join('\n')
      message.setRangeText(replaced, from, to, 'select')
      return message.value
    }
    return null
  }

  addListPrefix (message, from, to, textSelected, firstTag) {
    if (textSelected.length > 0) {
      const tmp = textSelected.split('\n')
      const res = []

      for (const frag of tmp) {
        res.push(`${firstTag} ${frag}`)
      }

      const replaced = res.join('\n')
      message.setRangeText(replaced, from, to, 'select')
      return message.value
    }
    return null
  }

  addHtmlTag (message, from, to, textSelected, tagOpen, tagClose) {
    if (textSelected.length > 0) {
      message.setRangeText(`${tagOpen}${textSelected}${tagClose}`, from, to, 'select')
      return message.value
    }
    return false
  }
}
